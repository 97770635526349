import history from '@src/history';
import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import { constants } from '@redux/modules/authentication';
import * as api from '@redux/api/authentication';
import moment from 'moment';

import { getSocketIoClient } from '@shared/socket-io';
import { getFingerprint, getJwtData } from '@src/shared/Util';

import { authService } from '@ct-internal/frontend-utilities';

export function* login(action) {
  try {
    const { location } = action;
    const fingerprint = yield call(getFingerprint);

    // Sets default user data in authService for first login
    authService.setUser({
      accessToken: 'initial',
      refreshToken: 'initial',
      expires: moment().add(1, 'day').unix(),
    });
    authService.setFingerprint(fingerprint);

    const payload = yield call(api.login, { ...action, fingerprint });

    const jwtData = getJwtData(payload);
    const { user } = jwtData;

    // Sets user in authService after first login
    authService.setUser(user);

    let { permissions } = jwtData;

    yield put({
      type: constants.AUTHENTICATION_LOGIN.SUCCESS,
      user,
      fingerprint,
    });

    if (!permissions) {
      // If login successful, setup the permissions
      permissions = yield call(api.getPermissions);
    }

    yield put({
      type: constants.AUTHENTICATION_GET_PERMISSIONS.SUCCESS,
      permissions,
    });

    const defaultPath = user.isAdmin ? '/admin/dashboard' : '/';
    yield call(history.push, location ? location.pathname : defaultPath);

    const socket = yield call(getSocketIoClient);
    if (socket) {
      socket.connect();
    }
  } catch (e) {
    yield put({
      type: constants.AUTHENTICATION_LOGIN.FAILURE,
      message: e.message || e,
    });
  }
}

export function* loginWithToken(action) {
  try {
    const { location } = action;
    const fingerprint = yield call(getFingerprint);

    // Sets default user data in authService for first login
    authService.setUser({
      accessToken: 'initial',
      refreshToken: 'initial',
      expires: moment().add(1, 'day').unix(),
    });
    authService.setFingerprint(fingerprint);

    const payload = yield call(api.loginWithToken, { ...action, fingerprint });

    const jwtData = getJwtData(payload);
    const { user } = jwtData;

    // Sets user in authService after first login
    authService.setUser(user);

    let { permissions } = jwtData;

    yield put({
      type: constants.AUTHENTICATION_LOGIN.SUCCESS,
      user,
      fingerprint,
    });

    if (!permissions) {
      // If login successful, setup the permissions
      permissions = yield call(api.getPermissions);
    }

    yield put({
      type: constants.AUTHENTICATION_GET_PERMISSIONS.SUCCESS,
      permissions,
    });

    const defaultPath = user.isAdmin ? '/admin/dashboard' : '/';
    yield call(history.push, location ? location.pathname : defaultPath);

    const socket = yield call(getSocketIoClient);
    if (socket) {
      socket.connect();
    }
  } catch (e) {
    yield put({
      type: constants.AUTHENTICATION_LOGIN.FAILURE,
      message: e.message || e,
    });
  }
}

export function* watchLogin() {
  yield takeLatest(constants.AUTHENTICATION_LOGIN.REQUEST, login);
}

export function* watchLoginToken() {
  yield takeLatest(constants.AUTHENTICATION_LOGIN_TOKEN.REQUEST, loginWithToken);
}

export function* mocklogin(action) {
  try {
    const { location } = action;
    const fingerprint = yield call(getFingerprint);
    const user = yield call(api.mocklogin, action);

    yield put({
      type: constants.AUTHENTICATION_LOGIN.SUCCESS,
      user,
      fingerprint,
    });

    const defaultPath = user.isAdmin ? '/admin/dashboard' : '/';
    yield call(history.push, location ? location.pathname : defaultPath);
  } catch (e) {
    yield put({
      type: constants.AUTHENTICATION_LOGIN.FAILURE,
      message: e.message || e,
    });
  }
}

export function* watchMockLogin() {
  yield takeLatest(constants.AUTHENTICATION_MOCK.REQUEST, mocklogin);
}

// NOTE: we don't use "refresh" saga, we do that in react-app/src/shared/request.js
export function* refresh(action) {
  try {
    const fingerprint = yield call(getFingerprint);
    const payload = yield call(api.refresh, { ...action, fingerprint });

    const { user, permissions } = getJwtData(payload);

    // const socket = yield call(getWebsocketClient);
    // if (socket) {
    //   socket.reconnect();
    // }

    // payload here is hire user
    yield put({ type: constants.AUTHENTICATION_REFRESH.SUCCESS, user });

    if (permissions) {
      yield put({
        type: constants.AUTHENTICATION_GET_PERMISSIONS.SUCCESS,
        permissions,
      });
    }

    // eslint-disable-next-line no-console
    console.log('token refreshed succesfully');
  } catch (e) {
    yield put({
      type: constants.AUTHENTICATION_REFRESH.FAILURE,
      message: e.message || e,
    });
  }
}

export function* watchRefresh() {
  yield takeLatest(constants.AUTHENTICATION_REFRESH.REQUEST, refresh);
}

export function* getPermissions(action) {
  try {
    // If login successful, setup the permissions
    const permissions = yield call(api.getPermissions);
    yield put({
      type: constants.AUTHENTICATION_GET_PERMISSIONS.SUCCESS,
      permissions,
    });
  } catch (e) {
    yield put({
      type: constants.AUTHENTICATION_GET_PERMISSIONS.FAILURE,
      message: e.message || e,
    });
  }
}

export function* watchGetPermissions() {
  yield takeLatest(constants.AUTHENTICATION_GET_PERMISSIONS.REQUEST, getPermissions);
}

export function* logoutUser(action) {
  // Reset user in authService
  authService.setUser(null);
  authService.setFingerprint(null);

  message.success('You have successfully logged out');
  // const socket = yield call(getWebsocketClient);
  const socket = yield call(getSocketIoClient);
  if (socket) {
    socket.disconnect();
  }

  yield call(history.push, '/');
}

export function* watchLogoutUser() {
  yield takeLatest(constants.AUTHENTICATION_LOGOUT.REQUEST, logoutUser);
}

export function* getAccount(action) {
  try {
    yield call(api.getAccount, action);
  } catch (e) {
    yield put({
      type: constants.AUTHENTICATION_GET_ACCOUNT.FAILURE,
      message: e.message || e,
    });
  }
}

export function* watchGetAccount() {
  yield takeLatest(constants.AUTHENTICATION_GET_ACCOUNT.REQUEST, getAccount);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetAccount),
    fork(watchLogin),
    fork(watchMockLogin),
    fork(watchLogoutUser),
    fork(watchRefresh),
    fork(watchGetPermissions),
    fork(watchLoginToken),
  ]);
}
