/* eslint-disable max-lines */
/* eslint-disable complexity */
import queryString from 'query-string';
import qs from 'qs';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';

import omit from 'lodash/omit';
import map from 'lodash/map';
import each from 'lodash/each';
import get from 'lodash/get';
import set from 'lodash/set';
import size from 'lodash/size';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import isNull from 'lodash/isNull';
import compact from 'lodash/compact';

import copy from 'copy-to-clipboard';

dayjs.extend(isBetween);

export const setClipboardText = (text) => {
  return copy(text);
};

export const serializeToUrl = (state) => {
  const { interviewFilter, selectedAssignments } = state;
  const queryParams = omit(state, [
    'reachDateFrom',
    'reachDateTo',
    'interviewFilter',
    'funnelTab',
    'filterDropdownVisible',
    'defaultCurrentPage',
    'tableUI',
    'selectedAssignments',
    'selectedAssignmentIds',
  ]);

  if (typeof interviewFilter.from !== 'string') {
    queryParams.interviewFilter = {
      ...interviewFilter,
      from: dayjs.isDayjs(interviewFilter.from) ? interviewFilter.from.format('') : null,
      to: dayjs.isDayjs(interviewFilter.to) ? interviewFilter.to.format('') : null,
    };
  }

  queryParams.assignment = map(
    selectedAssignments,
    (item) => (item && (item._id || item.id)) || null,
  ).filter(Boolean);

  queryParams.pageCode = 'applications';

  return qs.stringify(
    { search: queryParams },
    {
      strictNullHandling: true,
      arrayFormat: 'brackets',
    },
  );
};

export const parseFromUrl = (url) => {
  const parsed = qs.parse(url, { ignoreQueryPrefix: true, strictNullHandling: true });

  each(get(parsed, 'search.pagination'), (value, key) => {
    set(parsed, `search.pagination.${key}`, Number(value));
  });

  return parsed;
};

export const generateURL = (params, paramsToOmit) => {
  [
    'jobs',
    'status',
    'salary',
    'yearsOfExperience',
    'years',
    'tzOffset',
    'assignment',
    'assignments',
    'sources',
    'mediums',
    'authors',
    'englishLevels',
    'seniorityLevels',
    'workingStatusLevels',
    'owners',
    'techInterviewers',
    'skills',
    'reviewedBy',
  ].forEach((propName) => {
    params[propName] = '';
  });

  // eslint-disable-next-line no-param-reassign
  params = [
    setMiscVar,
    setInterviewFilter,
    setAssignments,
    setAvailability,
    setJobs,
    setSources,
    setMediums,
    setAuthors,
    setOwners,
    setTechInterviewers,
    setStatus,
    setSalary,
    setYoe,
    setExcitement,
    setYears,
    setEnglishLevels,
    setSeniorityLevels,
    setWorkingStatusLevels,
    setTechTestStatus,
    setSkills,
    setTechTests,
    setTechTestsScore,
    setTechSkills,
    setTechSkillsScore,
    setReviewedBy,
  ].reduce(
    (paramsAcc, nextFunc) => {
      return { ...paramsAcc, ...nextFunc(paramsAcc) };
    },
    { ...params },
  );

  if (paramsToOmit) {
    // eslint-disable-next-line no-param-reassign
    params = omit(params, paramsToOmit);
  }

  // if (params.applications_tz_filter_from && params.applications_tz_filter_to) {
  //   params.tzOffset = [
  //     params.applications_tz_filter_from || '',
  //     params.applications_tz_filter_to || ''
  //   ].join(':');
  // }
  params.pageCode = 'applications';

  params.tzOffset = getFieldRangeParam(params.applications_tz_filter_from) || '';

  params.testScore =
    getFieldRangeParam(params.applications_score_from, ['-1'], {
      getVal: (val) => val / 10,
    }) || '';

  // eslint-disable-next-line no-param-reassign
  params = {
    ...params,
    selectedUtm: params.selectedUtm || '',
    limit: params.limit ? params.limit : '25',
    sortParam: params.sortParam,
    position: params.position,
    offset: params.offset ? params.offset : '0',
  };

  const excludedKeys = [
    'offset',
    'status',
    'jobs',
    'sources',
    'mediums',
    'authors',
    'owners',
    'techInterviewers',
    'reviewedBy',
    'miscvar',
    'applications_salary_from',
    'applications_salary_to',
    'applications_yoe_from',
    'applications_yoe_to',
    'applications_score_from',
    'applications_excitement_from',
    'applications_excitement_to',
    'applications_tz_filter_from',
    'applications_tz_filter_to',
    'applications_years_from',
    'applications_years_to',
    'filterDropdownVisible',
    'filtered',
    'assignment',
    'selectedJobs',
    'selectedSources',
    'selectedUtmParams',
    'selectedAuthors',
    'selectedStatus',
    'selectedTechTestStatus',
    'selectedOwners',
    'selectedTechInterviewers',
    'selectedReviewedBy',
    'techTestStatus',
    'availability',
    'selectedAvailability',
    'selectedAssignments',
    'selectedAssignmentIds',
    'sortOrder',
    'defaultCurrentPage',
    'tableUI',
    'funnelTab',
    'interviewFilter',
    'englishLevels',
    'selectedEnglishLevels',
    'seniorityLevels',
    'selectedSeniorityLevels',
    'workingStatusLevels',
    'selectedWorkingStatusLevels',
    'search',
    'skills',
    'selectedSkills',
    'selectedTechTests',
    'techTestsScoreFrom',
    'techTestsScoreTo',
    'selectedTechSkills',
    'techSkillsScoreFrom',
    'techSkillsScoreTo',
  ];

  const filteredObjectKeys = Object.keys(params).filter((key) => !excludedKeys.includes(key));

  const route =
    filteredObjectKeys.reduce((accumulator, objectKey) => {
      const tail = accumulator ? accumulator + '&' : '';

      let value = !isUndefined(params[objectKey]) ? params[objectKey] : '';
      if (objectKey === 'kw') {
        value = encodeURIComponent(value);
      }

      return `${tail}${objectKey}=${value}`;
    }, '') +
    `&offset=${params.offset}` +
    `${params.status || ''}` +
    `${params.availability || ''}` +
    `${params.jobs || ''}` +
    `${params.sources || ''}` +
    `${params.mediums || ''}` +
    `${params.authors || ''}` +
    `${params.owners || ''}` +
    `${params.techInterviewers || ''}` +
    `${params.reviewedBy || ''}` +
    `${params.miscvar || ''}` +
    `${params.assignment || ''}` +
    `${params.englishLevels || ''}` +
    `${params.seniorityLevels || ''}` +
    `${params.workingStatusLevels || ''}` +
    `${params.techTestStatus || ''}` +
    `${params.skills || ''}`;

  return route;
};

export const getJobsFromParams = (params) => {
  if (!params) {
    return undefined;
  }
  if (!params.jobs) {
    return undefined;
  }
  if (params.jobs.length) {
    return params.jobs.filter((job) => !isEmpty(job));
  }
};

export const getStatusesFromParams = (params) => {
  if (!params) {
    return undefined;
  }
  if (!params['statuses[]']) {
    return undefined;
  }
  if (!isArray(params['statuses[]']) && !isEmpty(params['statuses[]'])) {
    return [params['statuses[]']];
  }
  if (params['statuses[]'].length) {
    return params['statuses[]'].filter((status) => !isEmpty(status));
  }
};

export const getTechTestStatusesFromParams = (params) => {
  if (!params) {
    return undefined;
  }
  if (!params['techTestStatuses[]']) {
    return undefined;
  }
  if (!isArray(params['techTestStatuses[]']) && !isEmpty(params['techTestStatuses[]'])) {
    return [params['techTestStatuses[]']];
  }
  if (params['techTestStatuses[]'].length) {
    return params['techTestStatuses[]'].filter((status) => !isEmpty(status));
  }
};

export const findInterviewsByRange = (record, range, interviewer) => {
  if (!record || !record.interviews) {
    return null;
  }

  const { interviews } = record;
  const foundInterviews = filter(interviews, (interview) => {
    const { status, datetime, interviewer: iver } = interview;
    const ivSelect = !interviewer || interviewer === iver;
    // if (!interviewer) ivSelect = true;

    return (
      (range &&
        dayjs(datetime).isBetween(range.start, range.end) &&
        (status === 1 || status === 2) &&
        ivSelect) ||
      false
    );
  });

  return foundInterviews;
};

// private functions

//param setters

const setYears = (params) => {
  const { applications_years_from, applications_years_to } = params;

  if (!applications_years_from && !applications_years_to) {
    return params;
  }

  const currentYear = new Date().getFullYear();
  const years = [1900, currentYear];

  if (applications_years_from) {
    years[1] = currentYear - parseInt(applications_years_from, 10);
  }

  if (applications_years_to) {
    years[0] = currentYear - parseInt(applications_years_to, 10);
  }

  params.years = years.join(':');
  return params;
};

const setSalary = (params) => {
  if (params.applications_salary_from || params.applications_salary_to) {
    params.salary = [
      params.applications_salary_from || 0,
      params.applications_salary_to || '',
    ].join(':');
  }
  return params;
};

const setYoe = (params) => {
  if (params.applications_yoe_from || params.applications_yoe_to) {
    params.yearsOfExperience = [
      params.applications_yoe_from || 0,
      params.applications_yoe_to || '',
    ].join(':');
  }
  return params;
};

const setExcitement = (params) => {
  if (params.applications_excitement_from || params.applications_excitement_to) {
    params.excitement = [
      params.applications_excitement_from || 0,
      params.applications_excitement_to || '',
    ].join(':');
  }
  return params;
};

const setStatus = (params) => {
  if (params.selectedStatus && params.selectedStatus.length !== 0) {
    const statuses = [...params.selectedStatus];

    params.status = queryString.stringify({ statuses }, { arrayFormat: 'bracket' });
    params.status = encodeURI('&' + params.status);
  }
  return params;
};

const setTechTestStatus = (params) => {
  if (params.selectedTechTestStatus && params.selectedTechTestStatus.length !== 0) {
    const techTestStatuses = [...params.selectedTechTestStatus];

    params.techTestStatus = queryString.stringify({ techTestStatuses }, { arrayFormat: 'bracket' });
    params.techTestStatus = encodeURI('&' + params.techTestStatus);
  }

  return params;
};

const setJobs = (params) => {
  if (params.selectedJobs && params.selectedJobs.length !== 0) {
    const jobs = [...params.selectedJobs];

    params.jobs = queryString.stringify({ jobs }, { arrayFormat: 'bracket' });
    params.jobs = encodeURI('&' + params.jobs);
  }
  return params;
};

const setSources = (params) => {
  if (params.selectedSources && params.selectedSources.length !== 0) {
    const sources = [...params.selectedSources];

    params.sources = queryString.stringify({ sources }, { arrayFormat: 'bracket' });
    params.sources = encodeURI('&' + params.sources);
  }
  return params;
};

const setMediums = (params) => {
  if (params.selectedUtmParams && params.selectedUtmParams.length !== 0) {
    const mediums = [...params.selectedUtmParams];

    params.mediums = queryString.stringify({ mediums }, { arrayFormat: 'bracket' });
    params.mediums = encodeURI('&' + params.mediums);
  }
  return params;
};

const setEnglishLevels = (params) => {
  if (params.selectedEnglishLevels && params.selectedEnglishLevels.length !== 0) {
    const englishLevels = [...params.selectedEnglishLevels];

    params.englishLevels = queryString.stringify({ englishLevels }, { arrayFormat: 'bracket' });
    params.englishLevels = encodeURI('&' + params.englishLevels);
  }

  return params;
};

const setSeniorityLevels = (params) => {
  if (params.selectedSeniorityLevels && params.selectedSeniorityLevels.length !== 0) {
    const seniorityLevels = [...params.selectedSeniorityLevels];

    params.seniorityLevels = queryString.stringify({ seniorityLevels }, { arrayFormat: 'bracket' });
    params.seniorityLevels = encodeURI('&' + params.seniorityLevels);
  }

  return params;
};

const setWorkingStatusLevels = (params) => {
  if (params.selectedWorkingStatusLevels && params.selectedWorkingStatusLevels.length !== 0) {
    const workingStatusLevels = [...params.selectedWorkingStatusLevels];

    params.workingStatusLevels = queryString.stringify(
      { workingStatusLevels },
      { arrayFormat: 'bracket' },
    );
    params.workingStatusLevels = encodeURI('&' + params.workingStatusLevels);
  }

  return params;
};

const setAuthors = (params) => {
  if (params.selectedAuthors && params.selectedAuthors.length !== 0) {
    const authors = params.selectedAuthors.map((selectedAuthor) => selectedAuthor.key);

    params.authors = queryString.stringify({ authors }, { arrayFormat: 'bracket' });
    params.authors = params.authors ? encodeURI('&' + params.authors) : '';
  }
  return params;
};

const setOwners = (params) => {
  if (params.selectedOwners && params.selectedOwners.length !== 0) {
    const owners = [...params.selectedOwners].filter(Boolean);

    params.owners = queryString.stringify({ owners }, { arrayFormat: 'bracket' });
    params.owners = encodeURI('&' + params.owners);
  }

  return params;
};

const setTechInterviewers = (params) => {
  if (isArray(params.selectedTechInterviewers)) {
    const techInterviewers = compact([...params.selectedTechInterviewers]);

    if (!isEmpty(techInterviewers)) {
      params.techInterviewers = queryString.stringify(
        { techInterviewers },
        { arrayFormat: 'bracket' },
      );
      params.techInterviewers = encodeURI('&' + params.techInterviewers);
    }
  }
  return params;
};

const setReviewedBy = (params) => {
  if (isArray(params.selectedReviewedBy)) {
    const reviewedBy = compact([...params.selectedReviewedBy]);

    if (!isEmpty(reviewedBy)) {
      params.reviewedBy = queryString.stringify({ reviewedBy }, { arrayFormat: 'bracket' });
      params.reviewedBy = encodeURI('&' + params.reviewedBy);
    }
  }
  return params;
};

const setAvailability = (params) => {
  each(
    {
      availability: params.selectedAvailability,
    },
    (data, key) => {
      params[key] = [];
      if (data && data.length !== 0) {
        const obj = { [key]: [].concat(data) };

        params[key] = queryString.stringify(obj, { arrayFormat: 'bracket' });
        params[key] = encodeURI('&' + params[key]);
      }
    },
  );
  return params;
};

const setAssignments = (params) => {
  const selectedAssignmentIds =
    params.selectedAssignmentIds && params.selectedAssignmentIds.length > 0
      ? params.selectedAssignmentIds
      : map(params.selectedAssignments, '_id');

  if (selectedAssignmentIds && selectedAssignmentIds.length > 0) {
    const urlAssigments = selectedAssignmentIds; //.map(item => item.id || item._id);
    params.assignment = '';

    if (urlAssigments.includes('has_assignments')) {
      params.assignments = 'true';
    } else if (urlAssigments.includes('has_recently_removed_assignments')) {
      params.assignments = 'recently-removed';
    } else if (urlAssigments.includes('has_no_client_assignments')) {
      params.assignments = 'no-client';
    } else if (urlAssigments.includes('has_no_core_assignments')) {
      params.assignments = 'no-core';
    } else if (urlAssigments.includes('has_no_assignments')) {
      params.assignments = 'false';
    } else {
      params.assignments = 'true';
      params.assignment = queryString.stringify(
        {
          assignment: urlAssigments,
        },
        {
          arrayFormat: 'bracket',
        },
      );
    }

    params.assignment = params.assignment ? '&' + params.assignment : '';
  }
  return params;
};

const setInterviewFilter = (params) => {
  const interviewFilter = params.interviewFilter;
  if (interviewFilter && interviewFilter.enabled !== '0') {
    params.interviewFilterEnabled = interviewFilter.enabled;

    if (interviewFilter.from) {
      params.interviewFrom = dayjs.isDayjs(interviewFilter.from)
        ? interviewFilter.from.format('YYYY-MM-DD')
        : dayjs(interviewFilter.from).format('YYYY-MM-DD');
    }

    if (interviewFilter.to) {
      params.interviewTo = dayjs.isDayjs(interviewFilter.to)
        ? interviewFilter.to.format('YYYY-MM-DD')
        : dayjs(interviewFilter.to).format('YYYY-MM-DD');
    }

    const interviewers = get(interviewFilter, 'interviewer', []).map((id) =>
      isNull(id) ? 'none' : id,
    );

    params.interviewInterviewer = params.interviewInterviewer || interviewers;
    params.interviewStatus = interviewFilter.status;
    params.hasNoFutureInterview = interviewFilter.hasNoFutureInterview || false;

    if (params.interviewInterviewer && params.interviewInterviewer.length !== 0) {
      const filteredInterviewers = [...params.interviewInterviewer].filter(Boolean);
      const interviewInterviewer = queryString.stringify(
        { interviewInterviewer: filteredInterviewers },
        { arrayFormat: 'bracket' },
      );

      params.interviewInterviewer = encodeURI('&' + interviewInterviewer);
    }
  } else {
    params.interviewFilterEnabled = '';
  }
  return params;
};

const setMiscVar = (params) => {
  if (params.misc && params.misc.length !== 0) {
    const miscArray = [];
    const paramsMisc = isString(params.misc) ? params.misc.split(',') : params.misc;
    paramsMisc.forEach((item) => {
      if (!params[item]) {
        miscArray[item] = true;
      }
    });
    params.miscvar = '&' + queryString.stringify(miscArray);
    return params;
  }
  params.miscvar = '';
  return params;
};

const setSkills = (params) => {
  if (params.selectedSkills && params.selectedSkills.length !== 0) {
    const skills = [...params.selectedSkills];

    params.skills = queryString.stringify({ skills }, { arrayFormat: 'bracket' });
    params.skills = encodeURI('&' + params.skills);
  }
  return params;
};

const setTechTests = (params) => {
  if (params.selectedTechTests && params.selectedTechTests.length !== 0) {
    const techTests = params.selectedTechTests.map((item) => item.key);

    params.techTests = queryString.stringify({ techTests }, { arrayFormat: 'bracket' });
    params.techTests = params.techTests ? encodeURI('&' + params.techTests) : '';
  }
  return params;
};

const setTechTestsScore = (params) => {
  if (params.techTestsScoreFrom || params.techTestsScoreTo) {
    params.techTestsScore = [params.techTestsScoreFrom || 0, params.techTestsScoreTo || 100].join(
      ':',
    );
  }
  return params;
};

const setTechSkills = (params) => {
  if (params.selectedTechSkills && params.selectedTechSkills.length !== 0) {
    const techSkills = params.selectedTechSkills;

    params.techSkills = queryString.stringify({ techSkills }, { arrayFormat: 'bracket' });
    params.techSkills = params.techSkills ? encodeURI('&' + params.techSkills) : '';
  }
  return params;
};

const setTechSkillsScore = (params) => {
  if (params.techSkillsScoreFrom || params.techSkillsScoreTo) {
    params.techSkillsScore = [
      params.techSkillsScoreFrom || 0,
      params.techSkillsScoreTo || 100,
    ].join(':');
  }
  return params;
};

export const getFieldRangeParam = function getFieldRangeParam(
  val = '',
  strictValues,
  { min = 0, max = 10, getVal } = {},
) {
  const fn = isFunction(getVal) ? getVal : (val) => val;

  if (!val) {
    return val;
  }

  if (size(strictValues) && includes(strictValues, val)) {
    return `${val}:${val}`;
  }

  const noSpace = val.replace(/\s/g, '');
  const rangeRegex = noSpace.match(/-?\d+(\.\d+)?:-?\d+(\.\d+)?/g);
  if (rangeRegex && rangeRegex[0]) {
    const result = rangeRegex[0].replace(/:/g, ',');
    const rangeArray = JSON.parse('[' + result + ']');
    return rangeArray.map((val) => fn(parseFloat(val))).join(':');
    // fn(parseFloat(rangeArray[0])) + ':' + fn(parseFloat(rangeArray[1]));
  } else {
    const number = fn(parseFloat(val));
    if (min <= number && number <= max) {
      return `${number}:`;
    }
  }

  return '';
};

export const getOptionForAssignmentQuery = (options) => {
  switch (true) {
    case includes(options, '3'):
      return ['1', '2', '3'];
    case includes(options, '2'):
      return ['1', '2'];
    case includes(options, '1'):
      return ['1'];
    default:
      return [];
  }
};

export const getOptionsForAssignment = (assignments, option) => {
  return filter(assignments, (assignment) => {
    // 1 = This is the applicant
    return assignment.optionForAssignment && assignment.optionForAssignment === option;
  });
};
