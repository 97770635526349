import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Row, Col, Radio, DatePicker } from 'antd';
import './InterviewerFilter.less';

dayjs.extend(customParseFormat);

const dateFormat = 'YYYY/MM/DD';

export const interviewFilters = {
  noFilter: 'NO_FILTER',
  inFuture: 'IN_FUTURE',
  inPast: 'PAST',
  requested: 'REQUESTED',
  canceled: 'CANCELED',
  hasNoFutureAndNoRequested: 'HAS_NO_FUTURE_AND_NO_REQUESTED',
  inTimeRange: 'IN_TIME_RANGE',
  noShow: 'NO_SHOW',
};

const InterviewFilter = ({
  interviewFilter = {},
  interviewOptions,
  onInterviewFilterChange,
  onInterviewFromChange,
  onInterviewToChange,
  // onInterviewPersonChange,
  // currentUser
}) => {
  const rowStyle = { marginTop: 10 };

  const getDateValue = (value) => {
    return value ? dayjs(value, dateFormat) : undefined;
  };

  const isDatepickerDisabled = interviewFilter.enabled !== interviewFilters.inTimeRange;

  return (
    <div className="interview-filter">
      <Row gutter={18} style={rowStyle}>
        <Col xs={24} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Radio.Group
            onChange={onInterviewFilterChange}
            value={interviewFilter.enabled}
            className="t-interview-radio-group"
          >
            <Radio className="radio" value={interviewFilters.noFilter}>
              No interview filter
            </Radio>
            <Radio className="radio" value={interviewFilters.requested}>
              Has interview requested
            </Radio>
            <Radio className="radio" value={interviewFilters.canceled}>
              Has interview canceled
            </Radio>
            <Radio className="radio" value={interviewFilters.inFuture}>
              Has interview in the future
            </Radio>
            <Radio className="radio" value={interviewFilters.inPast}>
              Has interviewed in the past
            </Radio>
            <Radio className="radio" value={interviewFilters.noShow}>
              No show
            </Radio>
            <Radio className="radio" value={interviewFilters.hasNoFutureAndNoRequested}>
              Has no interview in the future and no interview requested
            </Radio>
            <Radio className="radio" value={interviewFilters.inTimeRange}>
              Has interview in the time range
            </Radio>
          </Radio.Group>
        </Col>
      </Row>

      {
        <Row gutter={18} style={{ ...rowStyle }}>
          <Col xs={12} sm={12}>
            <DatePicker
              aria-label="Interview date from"
              disabled={isDatepickerDisabled}
              style={{ width: '100%' }}
              name="interviewFrom"
              placeholder="from"
              value={getDateValue(interviewFilter.from)}
              onChange={(date) => onInterviewFromChange(date)}
            />
          </Col>
          <Col xs={12} sm={12}>
            <DatePicker
              aria-label="Interview date to"
              disabled={isDatepickerDisabled}
              style={{ width: '100%' }}
              name="interviewTo"
              placeholder="to"
              value={getDateValue(interviewFilter.to)}
              onChange={(date) => onInterviewToChange(date)}
            />
          </Col>
        </Row>
      }
    </div>
  );
};

export default InterviewFilter;
