import { z } from 'zod';
import { dateString } from '../../infrastructure';

export enum ExpectedContractType {
  'T4' = 'T4',
  'W2' = 'W2',
  'Corporation' = 'Corporation',
  'Individual' = 'Individual',
  'Internship' = 'Internship',
  'Other' = 'Other',
}

export const ApplicationSchema = z.object({
  name: z.string().nullable(),
  email: z.string().nullable(),
  application_id: z.string().length(24),
  applicant_id: z.string(),
  job_id: z.string().length(24),
  recruiter_id: z.string().length(24),
  seniority: z.number().nullable(),
  english_level: z.number().nullable(),
  salary_value: z.number(),
  salary_currency: z.enum(['USD', 'CAD']),
  salary_usd: z.number(),
  status: z.number(),
  lead_experience: z.boolean(),
  excitement_rate: z.number().nullable(),
  auto_sign_contract_ct: z.boolean(),
  mgmt_reviewed: z.boolean(),
  auto_rejected: z.boolean().nullable(),
  automated_english_level: z.number().nullable(),
  automated_english_score: z.object({}).nullable(),

  yoe: z.number().nullable(),
  dt_applied: dateString,
  availability: z.string().nullable(),
  author: z.string().nullable(),
  source: z.string().nullable(),

  country: z.string().nullable(),
  country_code: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  timezone: z.string().nullable(),
  timezone_number: z.number().nullable(),
  timezone_short: z.string().nullable(),
  latitude: z.string().nullable(),
  longitude: z.string().nullable(),
  expected_contract_type: z.string().nullable(),
  tax_amount: z.number().nullable(),
  benefit_amount: z.number().nullable(),
  is_gem: z.boolean().nullable(),
  gem_by: z.string().nullable(),
  current_status: z.number().nullable(),
  workflow_run_id: z.number().nullable(),
  is_visa_required: z.boolean(),
  visa_details: z.string().nullable(),
  visa_status: z.number().nullable(),
  executive_presence: z.boolean().default(false).nullable(),
  tech_interviewer_id: z.string().nullable(),
});
export type Application = z.infer<typeof ApplicationSchema>;

export const AppDetailSchema = ApplicationSchema.pick({
  applicant_id: true,
  application_id: true,
  current_status: true,
  name: true,
  email: true,
  availability: true,
  seniority: true,
  english_level: true,
  yoe: true,
  status: true,
  dt_applied: true,
  excitement_rate: true,
  country: true,
  country_code: true,
  city: true,
  state: true,
  timezone: true,
  timezone_number: true,
  timezone_short: true,
  latitude: true,
  longitude: true,
  expected_contract_type: true,
  lead_experience: true,
}).extend({
  brains_id: z.string().nullable(),
  contract_status: z.string().nullable(),
  stage_id: z.string().nullable(),
  recruiter_id: z.string().nullable(),
  recruiter_name: z.string().nullable(),
  recruiter_email: z.string().nullable(),
  current_status_ref: z.string().nullable(),
  seniority_ref: z.string().nullable(),

  author_id: z.string().nullable(),
  author_name: z.string().nullable(),
  author_email: z.string().nullable(),

  job_id: z.string().nullable(),
  job_name: z.string().nullable(),
  job_description: z.string().nullable(),
  job_short_name: z.string().nullable(),

  cost: z.number().nullable(),
  salary_currency: z.string().nullable(),
  cost_currency: z.string().nullable(),
  usd_salary: z.number().nullable(),
  currency_salary: z.number().nullable(),
});
export type AppDetail = z.infer<typeof AppDetailSchema>;

export type ApplicationIsOpenToSales = {
  is_open_to_sales: boolean;
};
