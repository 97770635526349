import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { SFOpportunitySchema } from './base';

export const CreateSFOpportunityRequestSchema = SFOpportunitySchema.pick({
  opportunity_name: true,
  brains_client_id: true,
  brains_owner_id: true,
  revenue: true,
  sf_opportunity_id: true,
  stage: true,
  description: true,
  start_date: true,
  end_date: true,
  is_starter_project: true,
  next_update_on: true,
}).partial({
  is_starter_project: true,
  next_update_on: true,
});
export type CreateSFOpportunityRequest = z.infer<typeof CreateSFOpportunityRequestSchema>;

export const CreateSFOpportunityResponseSchema = z.object({
  brains_project_id: z.string(),
});
export type CreateSFOpportunityResponse = z.infer<typeof CreateSFOpportunityResponseSchema>;

export type CreateSFOpportunityEndpoint = Endpoint<
  Method.POST,
  '/api/sf/opportunities',
  CreateSFOpportunityResponse,
  undefined,
  undefined,
  CreateSFOpportunityRequest
>;
