import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Row, Col, Select, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import get from 'lodash/get';
import extend from 'lodash/extend';
import last from 'lodash/last';

import moment from 'moment';
import qs from 'qs';

import { useDebounce } from '@shared/hooks';
import { default as oneLineSummary } from '@shared/oneLineApplication';
import { SearchFormInitialState } from '@components/Search/InitialState';
import QuickSearchNoContentPlaceholder from './QuickSearchNoContentPlaceholder';
import { generateURL } from '@components/Search/_util';
import { NewInfoTooltip } from '@ct-internal/ui';

let searchActions;
import('@redux/search').then(({ actions }) => (searchActions = actions));

const defaultSearchParams = extend({}, SearchFormInitialState, {
  limit: 100,
  misc: [
    'showHiredAndOnboarded',
    'showApplying',
    'isApplication',
    'isReferred',
    'isSourced',
    'isSourcedDevTeam',
  ],
  countries: '0,1,2,3',

  dateFrom: '',
  touchedDateFrom: '',
  showHiredAndOnboarded: true,
  showRejected: false,
  showApplying: false,
  isApplication: true,
  isReferred: true,
  isSourced: true,
  isSourcedDevTeam: true,

  sortParam: 'updated_at',
  position: -1,
});

const QuickSearch = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // states
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [applicationId, setApplicationId] = useState(null);

  // redux state
  const results = useSelector((state) => get(state, 'search.navResults'));
  const loading = useSelector((state) => get(state, 'search.isLoadingNavSearch'));

  const goToManageApplications = () => {
    const currentParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const kw = searchParams && searchParams.kw;
    const _searchParams = {
      kw,
      search: {
        ...currentParams.search,
        kw,
      },
    };

    const route = `/admin/candidates?${qs.stringify(_searchParams)}`;

    return history.push(route);
  };

  const itemRenderer = (application, index) => {
    const lastAssignment = last(application.assignments);
    const appId = application._id; // || application.id; // in case of elastic search

    return {
      value: appId,
      label: (
        <div
          key={appId}
          appid={appId}
          text={searchParams.kw}
          value={application.name}
          label={appId}
          className="search-statuses-dropdown"
        >
          <Row>
            <Col span={16}>
              <b>{application.name}</b>
            </Col>
            <Col span={8}>{application.jobShortName}</Col>
          </Row>
          <Row style={{ color: '#aaa' }}>
            <Col span={16}>{oneLineSummary(application)}</Col>
            <Col span={8}>
              {lastAssignment ? (
                <span>{lastAssignment.name}</span>
              ) : (
                <div className={'application-status-inner-container'}>
                  <span className="application-badge" />
                  <span>{application.statusHuman}</span>
                </div>
              )}
            </Col>
          </Row>
          <Row style={{ color: '#aaa' }}>
            <Col span={24}>
              <small>
                Last interaction:{' '}
                {moment(application.updated_at).format('ddd, MMM Do YYYY, h:mm:ss a')}
              </small>
            </Col>
          </Row>
        </div>
      ),
    };
  };

  const buildDataSource = (array) => {
    const { kw } = searchParams;

    if (!kw) {
      return [];
    }

    const items = (array || []).map(itemRenderer);

    return items;
  };

  const triggerSearch = () => {
    const urlParams = generateURL(searchParams);
    dispatch(searchActions.navSearch({ urlParams }));
  };

  const debouncedKwValue = useDebounce(searchParams.kw, 500);
  useEffect(
    function fireOnChange() {
      if (debouncedKwValue) {
        triggerSearch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedKwValue],
  );

  const handleSearch = (value) => {
    if (!value || value.length < 3) {
      return;
    }

    setSearchParams({ ...searchParams, kw: value });
  };

  const handleSelect = (index, item) => {
    setApplicationId(item ? item.value : null);
    props.onBlur?.();

    if (item && item.value) {
      history.push(`/admin/application/${item.value}`);
    } else {
      goToManageApplications();
    }
  };

  const handleChange = (index, item) => {};

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSelect();
    }
  };

  // render
  const options = results ? buildDataSource(results.applications) : [];
  const handleSelectFn = props.handleSelect || handleSelect;
  const style = props.style || {};
  const searchInput = searchParams && searchParams.kw;
  const searchResult = results && results.applications;
  const shouldShowContent = searchInput && searchInput.length >= 3;
  const notFoundContent =
    shouldShowContent && (loading || !searchResult || searchResult.length === 0);

  return (
    <Space.Compact style={{ width: '100%', maxWidth: '600px' }}>
      <Select
        showSearch
        defaultActiveFirstOption={true}
        value={null}
        showArrow={false}
        filterOption={false}
        options={options}
        labelInValue
        notFoundContent={
          notFoundContent && (
            <QuickSearchNoContentPlaceholder
              searchResult={searchResult}
              isLoading={loading}
              filterRedirect={goToManageApplications}
            />
          )
        }
        loading={loading}
        placeholder={props.placeholder}
        onSearch={handleSearch}
        onSelect={handleSelectFn}
        onChange={handleChange}
        onInputKeyDown={handleKeyDown}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        style={style}
        className={'quick-search'}
        popupClassName={'quick-search-dropdown'}
        key={applicationId}
      />
      <div className="new-search-tooltip">
        <NewInfoTooltip showUntil="2024-11-07">
          The Search Bar has been modified to exclude by default applications that are in status{' '}
          <i>Rejected</i> or <i>Applying</i> If you are looking for an <i>Rejected</i> or{' '}
          <i>Applying</i> applicant, type in your search and add at the end <code>in:all</code>
        </NewInfoTooltip>
      </div>
      <Button
        className={'quick-search-button'}
        type="primary"
        icon={<SearchOutlined />}
        onClick={handleSelectFn}
      />
    </Space.Compact>
  );
};

export default QuickSearch;
